import { useMutation } from '@apollo/client'
import { AgGridReact } from 'ag-grid-react'
import { Button, DatePicker, Form, Modal, Select } from 'antd'
import dayjs from 'dayjs'
import { Plus } from 'lucide-react'
import { RefObject, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import {
  CreateServiceInvoiceDocument,
  GetServiceInvoicesDocument,
  GetServiceInvoicesQuery,
  ServiceInvoiceLineItemPaymentStatus,
  ServiceInvoicePaymentStatus,
  ServiceInvoiceType,
} from '../../../../generated/graphql'
import { useGlobalStore } from '../../../../stores/useGlobalStore'
import { InvoiceLineItemRecord } from '../InvoiceLineItemsView'

type CreateInvoiceFormValues = {
  paymentStatus: ServiceInvoicePaymentStatus
  type: ServiceInvoiceType
  issueDate: dayjs.Dayjs
  dueDate?: dayjs.Dayjs
}

type CreateInvoiceModalProps = {
  gridRef: RefObject<AgGridReact<InvoiceLineItemRecord>>
  onSuccess: () => void
}

export const CreateInvoiceModal = ({ gridRef, onSuccess }: CreateInvoiceModalProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation(['invoices', 'translation'])
  const [form] = Form.useForm<CreateInvoiceFormValues>()
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const [createInvoice] = useMutation(CreateServiceInvoiceDocument)

  const selectedLineItems = useCallback(() => {
    const items = gridRef.current?.api?.getSelectedRows() ?? []
    return {
      items,
      areAllPaid: items.every(
        (item) => item.paymentStatus === ServiceInvoiceLineItemPaymentStatus.PAID
      ),
    }
  }, [gridRef])

  const handleOpen = () => {
    if (selectedLineItems().items.length === 0) {
      toast.error(t('invoices:create.noSelection'))
      return
    }
    setIsOpen(true)
  }

  const handleSubmit = async (values: CreateInvoiceFormValues) => {
    try {
      await createInvoice({
        variables: {
          input: {
            creditorCompanyUuid: selectedCompany.uuid,
            type: values.type,
            issueDate: values.issueDate.format('YYYY-MM-DD'),
            dueDate: selectedLineItems().areAllPaid
              ? undefined
              : values.dueDate?.format('YYYY-MM-DD'),
            lineItems: selectedLineItems().items.map((item) => item.uuid),
          },
        },
        update: (cache, { data: mutationData }) => {
          if (!mutationData?.createServiceInvoice) return

          const today = dayjs()
          const startOfYear = today.startOf('year')
          const endOfYear = today.endOf('year')

          const existingData = cache.readQuery<GetServiceInvoicesQuery>({
            query: GetServiceInvoicesDocument,
            variables: {
              companyUuid: selectedCompany.uuid,
              from: startOfYear.format('YYYY-MM-DD'),
              to: endOfYear.format('YYYY-MM-DD'),
            },
          })

          if (existingData) {
            cache.writeQuery<GetServiceInvoicesQuery>({
              query: GetServiceInvoicesDocument,
              variables: {
                companyUuid: selectedCompany.uuid,
                from: startOfYear.format('YYYY-MM-DD'),
                to: endOfYear.format('YYYY-MM-DD'),
              },
              data: {
                serviceInvoices: [
                  mutationData.createServiceInvoice,
                  ...existingData.serviceInvoices,
                ],
              },
            })
          }
        },
      })

      toast.success(t('invoices:create.success'))
      onSuccess()
      setIsOpen(false)
    } catch (error) {
      console.error(error)
      toast.error(t('invoices:create.error'))
    }
  }

  useEffect(() => {
    if (isOpen) {
      form.setFieldsValue({
        paymentStatus: ServiceInvoicePaymentStatus.UNPAID,
        type: ServiceInvoiceType.INVOICE,
        issueDate: dayjs(),
        dueDate: selectedLineItems().areAllPaid ? undefined : dayjs().add(2, 'weeks'),
      })
    }
  }, [isOpen, form, selectedLineItems])

  return (
    <>
      <Button
        type="primary"
        icon={<Plus size={16} />}
        onClick={handleOpen}
        disabled={!selectedLineItems().items.length}
      >
        {t('invoices:create.title')}
      </Button>

      <Modal
        title={t('invoices:create.title')}
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        onOk={() => form.submit()}
        okText={t('translation:shared.button.submit')}
        cancelText={t('translation:shared.button.cancel')}
      >
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item name="type" label={t('invoices:create.type')} rules={[{ required: true }]}>
            <Select>
              {Object.values(ServiceInvoiceType).map((type) => (
                <Select.Option key={type} value={type}>
                  {t(`invoices:type.${type.toLowerCase() as Lowercase<ServiceInvoiceType>}`)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="issueDate"
            label={t('invoices:create.issueDate')}
            rules={[{ required: true }]}
          >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>

          {!selectedLineItems().areAllPaid && (
            <Form.Item
              name="dueDate"
              label={t('invoices:create.dueDate')}
              rules={[{ required: true, message: t('invoices:create.dueDateRequired') }]}
            >
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  )
}
